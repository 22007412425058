@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@font-face {
    font-family: "Haarlem";
    src: url('../fonts/Haarlem-Deco.otf') format("opentype");
}

body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
}

h2 {
    font-family: "Haarlem";
    font-weight: 500;
    font-size: 35px;
    margin-bottom: 1rem;
}

h2 span {
    font-size: 25px;
}

header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
}

header .nav-main ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100px;
}

/* Fixierter Header */
header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    background-color: transparent; /* Initial transparent */
    z-index: 1000;
    transition: background-color 0.3s ease, height 0.3s ease, padding 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center; /* Zentriert den Inhalt */
    padding: 0 20px;
    box-shadow: none; /* Optional: Kein Schatten für den transparenten Zustand */
}

/* Brand-Logo anfangs verstecken */
header .nav-main img.brand-logo-header {
    height: 40px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%) translateY(-100%); /* Logo außerhalb des Sichtbereichs */
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: 0;
}

/* Navigationselemente zentrieren */
header .nav-main ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 100%;
}

header .nav-main ul li {
    cursor: pointer;
}

/* Brand-Logo beim Scrollen anzeigen */
header.scrolled .nav-main img.brand-logo-header {
    transform: translateY(-50%); /* Logo sichtbar machen */
    opacity: 1;
}

/* Header beim Scrollen anpassen */
header.scrolled {
    background-color: white; /* Hintergrundfarbe nach dem Scrollen */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Schatten für besseren Kontrast */
    height: 60px;
    padding: 0 20px;
}

header.scrolled .nav-main img.brand-logo-header {
    height: 30px; /* Kleinere Logo-Größe beim Scrollen */
}

main {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

main .brand {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    background-image: url("../img/bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

main .brand .scroll-icon {
    position: absolute;
    bottom: 2rem;
}

main .home {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    flex-wrap: wrap;
}

main .home .home-text,
main .home .home-image {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
}

main .home .home-image {
    align-items: center;
    justify-content: end;
    height: 460px;
}

main .home .home-image img {
    max-width: 400px;
    width: 100%;
}

main .home .home-text p {
    margin-top: 0;
    font-weight: 400;
}

main .quote {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f3f3f3;
    background-image: url("../img/bg.png");
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    min-height: 275px;
    text-align: center;
}

main .quote .quote-text {
    font-size: 20px;
}

main .quote .quote-author,
main .quote .quote-job {
    font-size: 14px;
    font-weight: 300;
}

.works {
    width: 100%;
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.works .boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1.5rem;
    padding: 2rem 0;
    flex-wrap: wrap;
}

.works .boxes .big-box {
    flex-grow: 1;
    flex-shrink: 1;
    background: #D9D9D9;
    display: flex;
    align-items: center;
}

.works .boxes .small-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
}

.works .boxes .small-box .small {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
}

.works .boxes .small-box .small div {
    flex-grow: 1;
    flex-shrink: 1;
    background: #D9D9D9;
}

.contact {
    padding: 2.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
}

.contact .main-table {
    width: 100%;
}

.contact .second-table {
    max-width: 525px;
    width: 900%;
}

.contact .second-table form {
    width: 90%;
}

.contact .table {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
}

.contact .table .row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex-grow: 1;
    flex-shrink: 1;
}

.contact .table .text-row {
    text-align: center;
    gap: 0rem;
}

.contact .input-area {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0.5rem 1rem;
}

.contact .input-area label {
    font-weight: 400;
    font-size: 14px;
}

.contact .input-area input, .contact .input-area textarea {
    border-radius: 10px;
    background-color: #f1f1f1;
    border: 0;
    line-height: 28px;
    min-width: 245px;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 1rem;
}

.contact .input-area textarea {
    min-height: 100px;
}

.contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact button {
    border-radius: 10px;
    background-color: #f1f1f1;
    border: none;
    padding: 0.5rem 2.5rem;
    cursor: pointer;
}

.contact button:hover {
    background-color: #e0e0e0;
}

footer {
    background-color: #F5F5F5;
    color: #898988;
    font-size: 14px;
    text-align: center;
    padding: 2.5rem 0;
}

footer .table {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

footer .table .brand-footer,
footer .table .nav-footer,
footer .table .social-media {
    display: flex;
    max-width: 33%;
    width: 100%;
    align-items: center;
    justify-content: center;
}

footer .table .nav-footer ul {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    justify-content: center;
    padding: 0;
}

.works .big-box img {
    max-width: 525px;
}

.works .small-box img {
    max-width: 250px;
}

.socialMedia {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.75rem;
}

.socialMedia a {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
    font-size: 24px;
}

.socialMedia a:hover {
    color: #272727;
}